nav {
  z-index: 30;
  position: fixed;
  top: 0;
  height: 30px;
  box-shadow: 0 2px 0 0 #f5f5f5;
  padding: 1rem 2rem;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: var(--white);
}

.header-container {
  margin: 0;
  max-width: 1440px;
  width: 100%;
  display: flex;
  place-items: center;
}

.logo {
  width: 110px;
}

.links-container {
  margin-left: 30px;
}

.link {
  text-decoration: none;
  font-size: 14px;
  color: var(--grey);
  margin: 0 10px 0 10px;
}

.link-active,
.link:hover {
  font-weight: bold;
}
