.wrapper {
  display: grid;
  height: 100vh;
  background-color: var(--light-brown);
  place-items: center;
}

.container {
  padding: 20px;
}

.logo-container {
  display: grid;
  justify-content: center;
  margin-bottom: 50px;
}

.logo {
  width: 150px;
}

.error {
}
