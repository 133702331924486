.notification-wrapper {
  margin: 20px 0;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  max-width: 670px;
}

.notification-wrapper.success {
  background-color: var(--success);
}

.notification-wrapper.error {
  background-color: var(--error);
}

.notification-wrapper.info {
  background-color: var(--info);
}

.notification-wrapper.warning {
  background-color: var(--error);
}
