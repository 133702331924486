.main {
  margin-top: 30px;
  display: grid;
  place-items: center;
}

.content {
  margin: 0;
  margin-top: 0px;
  max-width: 1100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 3em;
}

.services-container {
  grid-column-start: 1;
  grid-column-end: 6;
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
}

.new-form,
.title {
  padding: 20px;
}
.title {
  border-bottom: 1px solid var(--ultra-light-grey);
  display: flex;
  position: relative;
}

.cancel-button {
  grid-column-start: 10;
  grid-column-end: 11;
  justify-self: end;
}

.save-button {
  grid-column-start: 12;
  grid-column-end: 13;
  justify-self: end;
}

.mobile-logo-link,
.mobile-inactive {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait),
  only screen and (min-device-width: 360px) and (max-device-height: 740px) and (orientation: portrait),
  only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait),
  only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait),
  only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait),
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .header-container {
    display: block;
  }

  .content {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    display: grid;
    grid-gap: 10px;
    padding: 0;
    margin-top: 50px;
  }
  .links-container {
    display: none;
  }

  .video-player {
    width: 100%;
    min-width: 0 !important;
  }

  .mobile-hamburger {
    display: inline;
    cursor: pointer;
    position: absolute;
    z-index: 3;
    margin-left: 10px;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 4px;
    background-color: var(--black);
    margin: 6px 0;
  }

  .mobile-logo-link {
    justify-content: center;
    align-content: center;
    display: flex;
    width: 95%;
  }

  .desktop-logo {
    display: none;
  }

  .mobile-active {
    width: 100%;
    height: 0;
    background-color: var(--white);
    z-index: 2;
    animation: slide 0.1s ease 0.1s forwards;
  }

  @keyframes slide {
    from {
      height: 0;
    }
    to {
      height: 100vh;
    }
  }

  .mobile-active > .link {
    display: block;
    width: 100%;
    font-size: 30px;
    text-align: center;
  }
}
