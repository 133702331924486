.box-card {
  width: 355px;
  height: 273px;
  box-shadow: 4px 10px 18px -8px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  position: relative;
  background-color: var(--white);
}

.box-full {
  width: 355px;
  height: auto;
  clear: both;
}

.service-image {
  width: 355px;
  height: 200px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.card-text-wrapper {
  padding-top: 60px;
  padding-left: 24px;
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
  background-color: var(--white);
  position: absolute;
  width: 276px;
  top: 135px;
}

.card-text-wrapper-full {
  padding: 10px;
}

.card-title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 23px;
}

.card-title-full {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  line-height: 25px;
}

.card-subtitle-full {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-right: 13px;
}

.summary-full {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding-right: 10px;
}

.card-resume {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  max-width: 302px;
}

.more {
  position: absolute;
  padding: 0.15em 0.75em;
  text-decoration: none;
  color: var(--black);
  background-color: var(--light-brown);
  right: -7px;
  top: 190px;
  z-index: 1;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 15px;
}
.more :after,
.more :before {
  content: ' ';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  bottom: -0.5em;
  left: -0.05em;
  pointer-events: none;
  -webkit-mask: none;
}
.more :after {
  left: auto;
  right: -0.05em;
  border-top: 0.5em solid var(--dark-brown);
  border-right: 0.5em solid transparent;
}

.document {
  text-decoration: none;
  font-size: 20px;
  border: 1px solid;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--black);
}

.document-text {
  font-size: 14px;
}

.play {
  text-decoration: none;
  font-size: 20px;
}

.important-message {
  color: var(--white);
  background-color: var(--error);
  width: 100%;
  margin-bottom: 2px;
  padding: 4px;
  border-radius: 5px;
}
