:root {
  --black: #000000;
  --white: #ffffff;
  --grey: #4a4a4a;
  --ultra-light-grey: #f0f0f0;
  --light-grey: #dbdbdb;
  --light-brown: #f4dec6;
  --dark-brown: #f5d1ab;
  --error: #ff9696;
  --success: #a2c480;
  --info: #a1dfe6;
  --warning: #fffd99;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-brown);
}

textarea:focus,
input:focus {
  outline: none;
}

textarea {
  width: 100%;
  min-height: 100px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
}

input[type='text'],
input[type='date'],
input[type='time'],
input[type='password'],
input[type='number'] {
  width: 100%;
  padding: 12px 20px;
  margin-top: 8px;
  display: inline-block;
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 5px;
}

input[type='number'] {
  appearance: textfield;
}

/* remove arrow fron number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  width: 100%;
  padding: 12px 20px;
  margin-top: 8px;
  display: inline-block;
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 5px;
}

select:before {
  display: none;
}

.html-textarea-wrapper {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 2px;
}

.form-field {
  margin-top: 12px;
  margin-bottom: 20px;
}

.field-error {
  color: var(--error);
  font-size: 12px;
}

.box {
  width: 360px;
  height: 410px;
  box-shadow: 4px 10px 18px -8px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  background-color: var(--white);
}

.button {
  background-color: var(--dark-brown);
  color: var(--black);
  padding: 7px;
  margin: 2px 8px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  text-decoration: none;
}

a.button {
  display: block;
  text-align: center;
}

.button-success {
  background-color: var(--success);
  color: var(--white);
  padding: 10px;
  margin: 0;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  min-width: 80px;
}

.button-error {
  background-color: var(--error);
  color: var(--white);
  padding: 10px;
  margin: 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 25px;
  font-size: 16px;
  min-width: 80px;
}

.circle-button {
  background-color: var(--dark-brown);
  color: var(--white);
  padding: 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  text-align: center;
  -moz-box-pack: center;
  justify-content: center;
  touch-action: none;
  -moz-box-align: center;
  align-items: center;
}

.button-fixed {
  width: 250px;
}

.circle-button:hover,
.button:hover {
  background-color: var(--light-brown);
}

.button:disabled {
  background-color: var(--light-grey);
  cursor: not-allowed;
}

.notificacion-is-error {
  background-color: #feecf0;
  color: #cc0f35;
  padding: 10px;
  border-radius: 5px;
}

sup {
  font-size: 11px;
  color: var(--grey);
}

.form-field {
  padding: 20px;
}

.message-container {
  padding: 10px;
}

.message-container div:nth-child(odd) {
  background-color: var(--light-brown);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 50px;
}

.message-container div:nth-child(even) {
  border: 1px solid var(--light-brown);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 50px;
}

.message-user {
  background-color: var(--light-brown);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 50px;
  margin-left: 0 !important;
}

.message-admin {
  background-color: var(--white) !important;
  border: 1px solid var(--light-brown) !important;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-right: 0 !important;
}

.audio-message {
  display: grid;
  justify-content: end;
}

.textarea-container {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid var(--light-grey);
}

.service-message {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--success);
  background-color: var(--success);
  width: 90%;
}

.buttons-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0;
  max-width: 305px;
}
