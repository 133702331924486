.questionryTitle > h1 {
  border-bottom: 1px solid var(--ultra-light-grey);
  display: flex;
  position: relative;
  padding: 10px;
}

.introContainer {
  padding: 10px;
}

.questionsContainer {
  padding: 10px;
}

.sections {
  border-bottom: 1px solid var(--ultra-light-grey);
}

.questionText {
  font-size: 16px;
}

.questionary-buttons {
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--white);
  bottom: 0;
}
